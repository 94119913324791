.login-header{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
}

.left img{
    height: 100vh;
    width: 100%;

    /* Image full width */
    object-fit: cover;
    
}

.right-side{
    align-items: center;
}

.btn-dark{
    background-color: #000 !important;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    box-shadow: none;
}
