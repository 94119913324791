@import "~mdb-ui-kit/css/mdb.min.css";
/* @font-face {
  font-family: "Roboto-Regular";
  src: url("../public/assets/fonts/Roboto/Roboto-Regular.ttf")
    format("truetype");
} */
@import url('https://fonts.cdnfonts.com/css/inter');

:root {
  --primary-color: black;
  --secondary-color: white;
  --paragraph-color: #797979;
  --hover-color: #f6f6f6;
  --redish-color: #ff6464;

  /* text customization */
  --text-black:black;
  --text-white:white;
  --paragraph-font: clamp(0.7rem, 1vw, 1rem);
  --heading-three: clamp(1rem, 1vw, 2rem);
  --card-heading :clamp(0.7rem, 1vw + 0.5em , 1.1rem);
  --card-paragraph:clamp(0.7rem, 1vw, 1rem);

  /* scroll customization  */
  --scrollbar-color:#797979;

  /* border customization  */
  --invalid-border:#DC4C64;

  --sidenav-color:black;

  --body-font:"Inter", sans-serif;
  --verified-color:#16C0FE;
  --mobile-screen:600px;
  --tablet-screen:768px,
  --laptop-screen:992px;
  --larger-screen:1200px;
 
}

.dark-mode {
  --primary-color: white;
  --secodary-color: black;
  --paragraph-color: #797979;
  --hover-color: #f6f6f6;
  --redish-color: #FF6464;
  --paragraph-font: clamp(0.7rem, 1vw, 1rem);
  --black-color:#0000005c;
}
*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;

  text-decoration: none;

  
}
::-webkit-scrollbar {
  width: 6px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: var(--scrollbar-color);
  border-radius: 10px;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button,
input[type=number]::-moz-number-spin-box {
  -webkit-appearance: none;
  margin: 0;
}


.bg-primary {
  background-color: var(--primary-color);
}
.bg-danger {
  background-color: var(--redish-color);
}
.cursor-text:hover{
  cursor: text;
}

.no-cursor{
  cursor: none;
}

body {
  margin: 0;
  padding: 0;
  /* roboto */
  font-family: 'Inter', sans-serif;
  scroll-behavior: auto;
  font-size: 16px;
  /* background-color: var(--secodary-color); */
}
/* ::-webkit-scrollbar {display:none;} */

.text-black{
  color:var(--text-black)
}
.text-paragraph {
  color: var(--paragraph-color);
}
.bg-hover {
  background-color: #F1F8FE;
}

.h-maxcontent {
  height: max-content;
}
.w-maxcontent{
  width: max-content;
}

code {
  font-family: 'Inter', sans-serif;
}
.grid-box {
  aspect-ratio: 1/0.9;
}

.grid-box-half {
  aspect-ratio: 1/0.5;
}



.grid-box-4 {
  aspect-ratio: 1/2/0.4;
}


.text-primary {
  color: var(--primary-color) !important;
}

p {
  margin: 0;
}

.bg-redish {
  background-color: var(--redish-color);
}

.bg-greyx {
  background-color: #dfdfdf;
}

.custom-container {
  padding-inline: 4vw;
}

.text-verified {
  color: var(--verified-color);
}

.nav-link.active > .nav-item {
  background-color: var(--hover-color);
}

.card-heading {
  font-size: var(--card-heading);
  line-height: 1em;
}

.card-paragraph {
  font-size: var(--card-paragraph);
  line-height: 1em;
}

.link {
  color: inherit;
}
.link:hover {
  color: inherit;
}

.border-black{
  border-color: var(--primary-color);
}


progress::-webkit-progress-value {
  background-color: black; 
  border-radius:5px;
}


progress::-moz-progress-bar {
  background-color: black; 
  border-radius:5px;
}

progress::-ms-fill {
  background-color: black; 
  border-radius:5px;
}

progress{
  background-color: black; 
  border-radius:5px;
}




.input-field-no-border {
  border: none;
  outline: none;
}

.input-field-no-border:focus {
  border: none;
  outline:none
}



.custom-search-input {
  border: none !important;
  border-bottom: none;
  outline: none;
}

.text-overflow-hidden{
  text-overflow: ellipsis;
  overflow: hidden;
  width:100%;
  white-space: nowrap;
}


@media (prefers-color-scheme: dark) {
  :root {
    --primary-color: white;
    --secodary-color: #202020;
    --paragraph-color: #797979;
    --hover-color: #f6f6f6;
    --redish-color: #ff6464;
    --paragraph-font: clamp(0.7rem, 1vw, 1rem);
     --text-black:white;
  --text-white:black;
  
  }
}

.react-draft-wysiwyg-editor img {
  max-width: 100% !important;
  height: auto !important;
}

.rdw-image-imagewrapper img {
  max-width: 100% !important;
  height: auto !important;
}

.blog-info img {
  max-width: 100% !important;
  height: auto !important;
  border-radius: 6px;
}


@keyframes fade-in {
  0% {
    opacity: 0;
    display: none;
  
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
    display: block;
    
  }
}

/* Apply the animation to the element */
.fadIn-1sec {
  animation-name: fade-in;
  animation-duration: 2.5s;
  animation-delay: 1s;
  animation-fill-mode: forwards; 
   /* keeps element at 100% opacity after animation completes */
}

.S9gUrf-YoZ4jf{
  width: 100%;
  
}


.custom-card{
  container-type:inline-size;
  container-name:custom-card;   
  
}


@container custom-card (max-width:300px){

   .orgtitle {
    font-size:calc( 0.4rem + 85%);
    font-weight:bold;
    
  }
   .cTitle {
    font-size:calc( 0.4rem + 75%);
    font-weight:bold;
    text-overflow: ellipsis;
    overflow: hidden;
    width:100%;
    white-space: nowrap;
    
  }
  .logo{
     
      width:50%;
      aspect-ratio:1/1;
      

  }

  .province{
    font-size:calc( 0.3rem + 50%);

  }

  .cIcon{
    font-size:calc(0.8rem + 50%) !important;
  }

  .description{
    font-size:calc( 0.3rem + 40%);
    display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;  
  overflow: hidden;


  }
  .category-box {
    font-size:calc( 0.4rem + 20%);
  white-space: nowrap;
    
    font-weight:bold;
  }

  .bcategory-box {
    font-size:calc( 0.2rem + 30%);

    font-weight:bold;
  }

}
@container custom-card (width > 300px){

  .orgtitle {
    font-size:calc( 0.4rem + 1vw);
    font-weight:bold;

  }

  .cTitle {
    font-size:calc( 0.9rem + 55%) !important;
    font-weight:bold !important;
    text-transform:capitalize;
    
  }

  .logo{
     
    width:calc(40px + 40%);
    aspect-ratio:1/1;

  }

  .description{
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;  
  overflow: hidden;


  }

  .cIcon{
    font-size:calc(1.2rem + 50%) !important;

  }
  

  

}