.loader {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    display: block; /* change to block to center horizontally */
    margin: 0 auto; /* set margin to center horizontally */
    position: fixed; /* position the loader relative to the viewport */
    top: 50%; /* align the top of the loader to the middle of the viewport */
    left: 50%; /* align the left of the loader to the middle of the viewport */
    transform: translate(-50%, -50%); /* center the loader perfectly */
    border-top: 3px solid #000000;
    border-right: 3px solid transparent;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }
  
  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  